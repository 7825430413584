@use "styles/config/constants/colors";

.wrapper {
  width: 100%;
  // height: 100vh;

  > main {
    // height: calc(100% - var(--gnb-height));
    // overflow-y: auto;
    width: 360px;
    margin: 0 auto;
  }
}
