@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";

dl.wrapper {
  > div {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  dt {
    margin-bottom: 10px;
    @include fontSize.spoqa-b-16;
  }

  dd {
    @include fontSize.spoqa-r-14;
    color: colors.$GRAY7;
  }
}
