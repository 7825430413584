@use "styles/config/constants/colors";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;

  .overlay {
    position: relative;
    z-index: 999;
    height: 100%;
    background-color: colors.$OVERLAY;

    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 999;
      width: 300px;
      min-height: 10px;
      padding: 25px;
      overflow-wrap: break-word;
      background-color: colors.$WHITE;
      border-radius: 16px;
      transform: translate(-50%, -50%);

      .xButton {
        position: fixed;
        top: 15px;
        right: 15px;
      }
    }
  }
}
