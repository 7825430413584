@use "styles/pages/point/mixin";
@use "styles/config/constants/colors";

.container {
  @include mixin.default-container();

  min-height: 610px;
  padding: 30px 16px;
  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .display {
    width: 100%;
    height: 142px;
    @include mixin.info-box();
    flex-direction: row;
    align-items: center;

    .arrow {
      margin: 0px 41px;
    }
    & > div {
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        width: 80px;
        font-weight: 700;
        letter-spacing: 0.3px;
        font-size: 20px;
      }
    }
  }
  .convert {
    width: 100%;
    height: 400px;
    @include mixin.stretch-both-side(true);
    align-items: center;
    .control {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .show_options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 33px;
        right: 10px;
        top: 12.3px;
        svg {
          cursor: pointer;
          z-index: 11;
        }
        .point_unit {
          font-size: 16px;
          color: #787878;
          &.error {
            color: colors.$RED;
          }
        }
      }

      .error_msg {
        font-size: 10px;
        font-weight: 400;
        color: colors.$RED;
        position: absolute;
        bottom: 35px;
        left: 10px;
      }

      & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .remain_point {
        position: absolute;
        bottom: 35px;
        right: 0px;
        text-align: end;
        font-size: 10px;
        font-weight: 400;
      }
    }
    input {
      width: 100%;
      text-align: right;
      padding: 10px 45px 10px 10px;
      border-bottom: 1.5px solid #787878;
      margin-bottom: 27px;
      color: #787878;
      background: transparent;
      z-index: 10;
      letter-spacing: -0.3px;

      &.small_padding {
        padding-right: 15px;
      }
      &.error {
        color: colors.$RED;
        border-color: colors.$RED;
      }
      &::placeholder {
        color: colors.$GRAY6;
      }
      &:-ms-input-placeholder {
        color: colors.$GRAY6;
      }
      &::-ms-input-placeholder {
        color: colors.$GRAY6;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
    }
    .placeholder {
      position: absolute;
      color: colors.$GRAY6;
      right: 10px;
      top: 13px;
      z-index: 1;
    }
  }
}
