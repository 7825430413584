@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";

.wrapper {
  display: flex;
  gap: 15px;
  width: 100%;
  padding: 24px 15px;
  background: colors.$WHITE;

  > button {
    @include fontSize.spoqa-b-16;
    padding-bottom: 2px;
    color: colors.$GRAY6;
    border-bottom: 1px solid colors.$WHITE;
  }

  .active {
    color: colors.$BLACK;
    border-bottom: 1px solid;
  }
}
