@use "styles/config/base/fonts";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 328px;
  height: 68px;
  border-radius: var(--border-radius);
  background-color: #f9f9f9;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  font-family: "Spoqa Han Sans Neo";
  line-height: 20px;
}
