@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";
@use "styles/config/mixins/flexbox";

.wrapper {
  .highlight {
    color: colors.$RED;
  }

  .guideBox {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .txtBox {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 5px;
      }

      > p {
        @include fontSize.spoqa-b-14;
      }
    }

    .example {
      @include fontSize.spoqa-r-14;
      padding-left: 23px;
    }
  }

  .target {
    > div {
      margin-bottom: 10px;
      color: colors.$GRAY7;
    }

    > ul {
      display: flex;
      flex-direction: column;
      gap: 7px;
      color: colors.$GRAY6;
    }

    > ul li {
      display: flex;
      align-items: center;
      padding-left: 10px;

      .icon {
        margin-left: 4px;
      }
    }
  }

  .dataContainer {
    display: flex;
    gap: 8px;
    margin-top: 12px;
  }
}
