@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";
@use "styles/config/mixins/flexbox";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 172px;
  padding: 24px 16px;
  background: colors.$GRAY2;
  border-radius: var(--border-radius);

  .iconBox {
    flex-grow: 1;
  }

  .date {
    @include fontSize.spoqa-b-14;
    margin-bottom: 5px;
  }

  .txt {
    @include fontSize.spoqa-r-14;
    color: colors.$GRAY6;
  }
}
