@mixin spoqa-b-12 {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

@mixin spoqa-b-14 {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

@mixin spoqa-b-16 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

@mixin spoqa-b-18 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

@mixin spoqa-b-20 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

@mixin spoqa-b-24 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

@mixin spoqa-b-30 {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
}

@mixin spoqa-b-38 {
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;
}

@mixin spoqa-r-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

@mixin spoqa-r-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

@mixin spoqa-r-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin spoqa-r-18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

@mixin spoqa-r-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

@mixin spoqa-r-24 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

@mixin spoqa-l-14 {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

@mixin spoqa-l-16 {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

@mixin spoqa-m-12 {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
