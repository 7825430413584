@use "styles/config/constants/colors";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 381px;
  .text {
    text-align: center;
    line-height: 25px;
    span {
      color: colors.$MAIN_RED;
    }
  }
  a {
    color: white;
  }
}
