// Global styles
@import "base/reset";
@import "base/more";
@import "base/fonts";

// html,
// body {
//   min-height: 100%;
// }

// #root {
//   min-height: 100%;
// }

:root {
  --gnb-height: 66px;
  --border-radius: 16px;
}

body,
button,
input,
textarea {
  font-family: "Spoqa Han Sans Neo", sans-serif;
}
