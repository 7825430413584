@use "styles/config/constants/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  padding: 8px;
  cursor: pointer;

  .bannerImgBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bannerImg {
      width: 338px;
      height: 190px;
      margin-bottom: 8px;
      border-radius: var(--border-radius);
    }
  }

  .name {
    height: 22px;
    margin-bottom: 8px;
    padding: 8px;

    span {
      color: colors.$MAIN_RED;
      font-size: 16px;
      font-weight: 700;
      font-family: "Spoqa Han Sans Neo";
    }

    .title {
      margin-left: 4px;
      color: colors.$BLACK;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

    span {
      color: colors.$MAIN_BLACK;
      font-size: 14px;
      font-weight: 400;
      font-family: "Spoqa Han Sans Neo";
    }

    .companyInfo {
      display: flex;
      align-items: center;

      .companyLogo {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }

    .point {
      color: colors.$MAIN_RED;
      font-weight: 700;
    }
  }
}
