@use "styles/pages/point/mixin";

.container {
  @include mixin.stretch-both-side(true);
  .confirm,
  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #222222;
  }
  .confirm {
    h2 {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 14px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 23px;
    }
    & > div {
      display: flex;

      button:first-child {
        margin-right: 5px;
      }
      button:last-child {
        margin-left: 5px;
      }
    }
  }
  .result {
    h2 {
      font-size: 16px;
      font-weight: 700;
      line-height: 14px;
      margin: 10px 0 24px 0;
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
        margin-bottom: 4px;
      }
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 12px;
      margin-bottom: 23px;
    }
  }
}
