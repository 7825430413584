@use "styles/config/constants/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    color: colors.$MAIN_BLACK;

    h2 {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 700;
      font-family: "Spoqa Han Sans Neo";
      line-height: 25px;

      span {
        margin-left: 4px;
      }
    }
  }

  .guide {
    margin-bottom: 20px;
    color: colors.$BLACK;
    font-size: 14px;
    font-weight: 400;
    font-family: "Spoqa Han Sans Neo";
    line-height: 22px;

    span {
      color: colors.$MAIN_RED;
      font-weight: 700;
    }
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 235px;
    height: 65px;
    margin-bottom: 20px;
    padding: 0 21px;
    background-color: #f2f2f2;
    color: #4f4f4f;
    font-size: 13px;
    font-weight: 400;
  }

  .btns {
    display: flex;

    button {
      margin: 0 5px;
      font-size: 11px;
      font-weight: 700;
    }
  }
}
