@use "styles/config/mixins/fontSize";
@use "styles/config/constants/colors";

.wrapper {
  position: sticky;
  top: -10px;
  z-index: 5;
  display: flex;
  align-items: center;
  height: var(--gnb-height);
  padding: 0 15px;
  padding-top: 10px;
  background: colors.$WHITE;
  border-bottom: 1px solid colors.$GRAY3;

  button[name="back"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding-bottom: 3px;
  }

  .arrow {
    transform: rotate(90deg);
  }

  > span {
    @include fontSize.spoqa-b-16;
  }

  button[name="home"] {
    height: 30px;
    margin-left: auto;
  }

  img[alt="logo"] {
    width: 51px;
  }
}
