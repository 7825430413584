@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .info {
    width: 100%;
    padding: 30px 15px;
    margin-top: 10px;
    background: colors.$WHITE;

    > h3 {
      @include fontSize.spoqa-b-18;
      margin-bottom: 24px;
    }
  }

  .content {
    width: 100%;
    padding: 24px 15px;
    background: colors.$WHITE;
  }

  .detailBox {
    margin: 10px 0;
  }

  .comment {
    @include fontSize.spoqa-r-12;
  }

  p.detail {
    @include fontSize.spoqa-r-12;
    color: colors.$GRAY7;
  }

  .btnBox {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    background: colors.$WHITE;
  }

  .highlight {
    color: colors.$RED;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  // width: 300px;
  height: 250px;

  img[alt="logo"] {
    margin-bottom: 40px;
  }

  > p {
    @include fontSize.spoqa-r-14;
    margin-bottom: 30px;
  }
}
