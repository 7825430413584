@use "styles/pages/point/mixin";
@use "styles/config/constants/colors";

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 390px;
  padding: 0px 16px;
  .recent_data {
    display: flex;
    padding: 15px 0 0 0;
    background: colors.$WHITE;
    color: colors.$BLACK;
    @include mixin.stretch-both-side();
    .down {
      transform: rotateX(180deg);
      transition: transform 0.4s ease-in-out;
    }
    .up {
      transform: rotateX(0deg);
      transition: transform 0.4s ease-in-out;
    }
    .left {
      display: flex;
      align-items: center;
      font-weight: 700;
      svg {
        margin-left: 5px;
      }
    }
  }
  ul {
    width: 100%;
    height: 330px;
    overflow: auto;
    margin-top: 25px;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // & {
    //   -ms-overflow-style: none;
    //   scrollbar-width: none;
    // }
    li {
      width: 100%;
      height: 38px;
      margin-bottom: 15px;
      @include mixin.stretch-both-side(true);

      div:first-child {
        font-size: 15px;
        color: colors.$GRAY9;
        margin-bottom: 5px;
        @include mixin.stretch-both-side();

        span:first-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      div:last-child {
        font-size: 12px;
        color: #7c7c7c;
      }
    }
  }
}
