@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";

.wrapper {
  width: 100%;
  background: colors.$WHITE;

  .imgBox {
    display: flex;
    justify-content: center;
    object-fit: cover;

    > img {
      width: 338px;
      height: 190px;
    }
  }

  .cotents {
    padding: 0 15px;
    padding-top: 10px;
    margin-bottom: 10px;

    .title {
      @include fontSize.spoqa-b-16;
      margin-bottom: 20px;
    }

    .point {
      @include fontSize.spoqa-b-24;
      margin-bottom: 10px;
      color: colors.$RED;
    }

    .date {
      @include fontSize.spoqa-r-14;
      margin-bottom: 40px;
      color: colors.$GRAY6;
    }

    .btnBox {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }
  }
}
