@use "styles/config/constants/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    padding: 15px 10px;
    background-color: colors.$WHITE;
  }
}
