@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";

.wrapper {
  .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > h3 {
      @include fontSize.spoqa-b-18;
    }

    svg {
      margin-right: 10px;
      transition: all 0.5s;

      &.active {
        transform: rotateX(180deg);
      }
    }
  }

  .content {
    margin-top: 20px;
  }
}
