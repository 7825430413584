@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";

.wrapper {
  width: 100%;
  padding: 30px 15px;
  background: colors.$WHITE;

  > h2 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .txt {
      @include fontSize.spoqa-b-24;
      margin-left: 13px;
    }
  }

  > p {
    @include fontSize.spoqa-r-14;
    color: colors.$GRAY7;
  }
}
