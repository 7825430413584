@use "styles/config/constants/colors";

@mixin border($colors) {
  border: 1px solid $colors;
}

.button {
  border-radius: var(--border-radius);
  color: colors.$WHITE;
}

.reactive {
  padding: 4px 16px;
  min-width: 60px;
  height: 28px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.small {
  width: 100px;
  height: 35px;
  padding: 10px;
  font-size: 10px;
  font-weight: 400;
}

.medium {
  padding: 8px;
  width: 130px;
  font-size: 12px;
  font-weight: 500;
  height: 42px;
}

.large {
  padding: 16px;
  width: 310px;
  height: 52px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.red {
  background: colors.$MAIN_RED;
  @include border(colors.$MAIN_RED);
}

.black {
  background: colors.$GRAY9;
  @include border(colors.$GRAY9);
}

.white {
  background: colors.$WHITE;
  color: colors.$GRAY9;
  @include border(colors.$GRAY9);
}

.light-grey {
  background: colors.$GRAY2;
  color: colors.$GRAY7;
  @include border(#cdcdcd);
}

.disabled {
  background-color: #f6f6f6;
  color: colors.$GRAY6;
  @include border(#f6f6f6);
}
