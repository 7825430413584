@use "styles/config/constants/colors";

@mixin box-radius() {
  border-radius: 8px;
}

@mixin info-box() {
  @include box-radius();
  border: 1px solid #dddddd;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin stretch-both-side($isDirCol: false) {
  display: flex;
  justify-content: space-between;

  @if $isDirCol {
    flex-direction: column;
  }
}

@mixin default-container() {
  padding: 30px 0px;
  background: colors.$WHITE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
