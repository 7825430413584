@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  src: url('/assets/fonts/SpoqaHanSansNeo-Light.woff2') format('woff2'),
    url('/assets/fonts/SpoqaHanSansNeo-Light.woff') format('woff'),
    url('/assets/fonts/SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  src: url('/assets/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
    url('/assets/fonts/SpoqaHanSansNeo-Regular.woff') format('woff'),
    url('/assets/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  src: url('/assets/fonts/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
    url('/assets/fonts/SpoqaHanSansNeo-Medium.woff') format('woff'),
    url('/assets/fonts/SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  src: url('/assets/fonts/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('/assets/fonts/SpoqaHanSansNeo-Bold.woff') format('woff'),
    url('/assets/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}
