@use "styles/config/constants/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 16px 16px 0 16px;

  .infoText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      color: colors.$MAIN_BLACK;
      font-size: 16px;
      font-weight: 700;
      font-family: "Spoqa Han Sans Neo";
    }

    .point {
      display: flex;
      align-items: center;

      span {
        margin-left: 4px;
        color: colors.$BLACK;
        font-size: 14px;
        font-weight: 400;
        font-family: "Spoqa Han Sans Neo";
      }
    }
  }

  .icon {
    height: 40px;
    margin-top: 2px;
    margin-right: 4px;
  }

  span {
    color: colors.$MAIN_RED;
    font-weight: 700;
  }
}
