@use "styles/pages/point/mixin";
@use "styles/config/constants/colors";

.container {
  @include mixin.default-container();

  span {
    letter-spacing: 0.1px;
  }
}

.manage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 218px;
  padding: 0px 16px;
  margin-bottom: 28px;

  .question {
    font-size: 12px;
    color: #858585;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-bottom: 16px;
  }
}

.point_box {
  @include mixin.info-box();
  margin-bottom: 10px;
  width: 328px;
  height: 110px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  div:first-child {
    margin-top: 5px;
    margin-bottom: 15px;
    @include mixin.stretch-both-side();

    span:first-child {
      font-size: 14px;
      font-weight: 700;
      color: #222222;
    }
    span:last-child {
      font-size: 19px;
      font-weight: 700;
      color: colors.$MAIN_RED;
    }
  }

  div:last-child {
    padding: 5px 0px;
    @include mixin.stretch-both-side();
    span {
      color: colors.$GRAY9;
      font-weight: 400;
      font-size: 12px;
    }
  }
}
