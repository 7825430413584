$WHITE: #ffffff;
$BLACK: #000000;
$MAIN_BLACK: #222222;

$RED: #f5222d;
$MAIN_RED: #fc596c;
$ORANGE: #ff6b18;
$YELLOW: #ffcc00;
$GREEN: #52c41a;
$GREEN2: #107d43;
$GREEN3: #f6ffed;
$TEAL: #1ab9de;
$BLUE: #1890ff;
$DEEP_BLUE: #353c49;
$DEEP_BLUE2: #505867;

$LIGHT_BLUE: #f0f7ff;
$LIGHT_BLUE2: #f8fbff;
$INDIGO: #5856d6;
$PURPLE: #af52de;
$PINK: #eb2f96;
$PINK2: #ffadd2;
$PINK3: #fff0f6;
$PINK4: #fff1f0;
$PINK5: #ffa39e;

$OVERLAY: #00000080;

$GRAY: #fafafa;
$GRAY2: #f5f5f5;
$GRAY3: #f0f0f0;
$GRAY4: #d9d9d9;
$GRAY5: #e7e9ee;
$GRAY6: #bfbfbf;
$GRAY7: #8c8c8c;
$GRAY8: #595959;
$GRAY9: #4b4b4b;
