@use "styles/config/constants/colors";
@use "styles/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5%;
  align-items: center;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100 - var(--gnb-height));

  .txtBox {
    text-align: center;

    > p:nth-child(1) {
      @include fontSize.spoqa-r-18;
    }

    > p:nth-child(2) {
      @include fontSize.spoqa-r-14;
      color: colors.$GRAY7;
    }
  }
}
